import React from "react";
import From from "../contacts/form";
import Heder from "../../components/navigation-header";
import Footer from "../../components/footer";
import MainBanner from "../../images/faq.jpg";
import QuestionCard from "../../components/QuestionCard";

const questions = [
  {
    question: "What is BPPL Holdings?",
    answer:
      "BPPL Holdings is a leading manufacturer and exporter of cleaning products, synthetic fiber, and monofilaments in Sri Lanka. We are committed to sustainability and innovation in all our operations.",
    no: 1,
  },
  {
    question: "How long has BPPL Holdings been in business?",
    answer:
      "BPPL Holdings has been in business since 1984, providing high-quality products and solutions to customers worldwide.",
    no: 2,
  },
  {
    question: "What types of products does BPPL Holdings manufacture?",
    answer:
      "We manufacture a wide range of products including brooms, brushes, mops, and cleaning accessories. Additionally, we produce synthetic fiber and monofilament for various industrial applications.",
    no: 3,
  },
  {
    question: "Can BPPL Holdings produce custom products?",
    answer:
      "Yes, we offer custom manufacturing services to meet the specific needs and requirements of our clients. Please contact us to discuss your custom product needs.",
    no: 4,
  },
  {
    question: "What sustainability initiatives does BPPL Holdings undertake?",
    answer:
      "BPPL Holdings is committed to sustainable practices including recycling plastic waste, using eco-friendly materials, and minimizing our environmental footprint through energy-efficient processes and waste reduction strategies.",
    no: 5,
  },
  {
    question: "How does BPPL Holdings contribute to plastic recycling?",
    answer:
      "We operate a plastic recycling facility where we convert post-consumer plastic waste into high-quality synthetic fiber. This initiative helps reduce plastic pollution and supports a circular economy.",
    no: 6,
  },
  {
    question: "How does BPPL Holdings ensure product quality?",
    answer:
      "We adhere to stringent quality control measures throughout the manufacturing process. Our products meet international quality standards, and we continuously invest in advanced technology and skilled workforce to maintain high quality.",
    no: 7,
  },
  {
    question: "What certifications does BPPL Holdings hold?",
    answer:
      "BPPL Holdings holds several certifications including ISO 9001 for quality management, ISO 14001 for environmental management, and SA8000 for social accountability. These certifications reflect our commitment to quality, sustainability, and ethical practices.",
    no: 8,
  },
  {
    question: "Which countries does BPPL Holdings export to?",
    answer:
      "We export our products to over 50 countries worldwide, including markets in North America, Europe, Asia, and Australia.",
    no: 9,
  },
  {
    question: "How can I become a distributor for BPPL Holdings products?",
    answer:
      "If you are interested in becoming a distributor, please contact our sales team through our website or email us at sales@bpplholdings.lk. We will be happy to discuss partnership opportunities.",
    no: 10,
  },
  {
    question: "Does BPPL Holdings invest in research and development?",
    answer:
      "Yes, we have a dedicated research and development team focused on innovation and improving our product offerings. Our R&D efforts ensure that we stay at the forefront of industry trends and meet the evolving needs of our customers.",
    no: 11,
  },
  {
    question:
      "Where can I find BPPL Holdings’ annual reports and financial statements?",
    answer:
      "You can access our annual reports and financial statements on our <a target='_blank' href='https://www.bpplholdings.lk/investor-relations/'>Investor Relations</a> page. These reports provide comprehensive information about our financial performance, business strategy, and sustainability initiatives.",
    no: 12,
  },
  {
    question: "Is BPPL Holdings a publicly listed company?",
    answer:
      "Yes, BPPL Holdings is a publicly listed company on the Colombo Stock Exchange (CSE). Our ticker symbol is BPPL. For more information, visit our <a target='_blank' href='https://www.cse.lk/pages/company-profile/company-profile.component.html?symbol=BPPL.N0000'>CSE profile</a>.",
    no: 13,
  },
  {
    question: "How can I apply for a job at BPPL Holdings?",
    answer:
      "To apply, email your resume and cover letter to info@bpplholdings.com.",
    no: 14,
  },
  {
    question: "What kind of career opportunities does BPPL Holdings offer?",
    answer:
      "We offer a wide range of career opportunities across various functions including manufacturing, engineering, sales and marketing, finance, and administration. BPPL Holdings is committed to employee development and providing a supportive work environment.",
    no: 15,
  },
  {
    question: "How can I contact BPPL Holdings for more information?",
    answer:
      "You can reach us through our website’s contact form, by emailing info@bpplholdings.com, or by calling our office at +94 11 230 7170. Our team is available to assist you with any inquiries.",
    no: 16,
  },
  {
    question: "Where is BPPL Holdings located?",
    answer:
      "BPPL Holdings is headquartered in Colombo, Sri Lanka, with manufacturing facilities located in Horana. Visit our website for detailed contact information and directions.",
    no: 17,
  },
];
export default function Faq() {
  return (
    <>
      <div>
        <Heder />

        <center>
          <div className="section-background">
            <h2 className="contacts-topic">Frequently Asked Questions</h2>
          </div>
        </center>

        <div className="pc">
          <div
            style={{
              backgroundImage: `url(${MainBanner})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100vh",
              marginBottom: "100px",
            }}
          >
            <div className="overlayStyle4"></div>
          </div>
        </div>

        <div className="mobile">
          <div
            style={{
              backgroundImage: `url(${MainBanner})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "700px",
              marginBottom: "100px",
            }}
          >
            <div className="overlayStyle5"></div>
          </div>
        </div>

        <div style={{ marginTop: "-70px" }}>
          <div style={{ padding: "70px 0px" }}>
            <center>
              <div className="section-background">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {questions.map((question, index) => {
                    return <QuestionCard props={question} />;
                  })}
                </div>
              </div>
            </center>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
