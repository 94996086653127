import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

export default function QuestionCard({ props }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);
  return (
    <div
      className="question-container"
      style={{
        width: "90%",
        minWidth: "280px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px 20px",
        backgroundColor: "white",
        margin: "0px 0px 20px 0px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        cursor: "pointer",
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
      }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="question-header">
        <h1>{props.question}</h1>
        <FontAwesomeIcon
          className="quesion-open-button"
          icon={isOpen ? faMinus : faPlus}
          style={{ height: "1.2em", color: "#1b1464" }}
        />
      </div>
      {isOpen ? (
        <div
          className="answer-container"
          style={{
            width: "100%",
            height: "auto",
            margin: "10px 0",
            color: "#61677A",
          }}
        >
          {props.no == 12 ? (
            <div
              dangerouslySetInnerHTML={{ __html: props.answer }}
              className="anchored-text"
            />
          ) : props.no == 13 ? (
            <div
              dangerouslySetInnerHTML={{ __html: props.answer }}
              className="anchored-text"
            />
          ) : (
            props.answer
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
